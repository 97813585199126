import React from "react";
import http from "../../utils/http";
import { useEffect } from "react";

/**
 * Component to handle file upload. Works for image
 * uploads, but can be edited to work for any file.
 */
function FileUpload({ id, showInput = true }) {
  // State to store uploaded file
  const [file, setFile] = React.useState("");

  // Handles file upload event and updates state
  function handleUpload(event) {
    setFile(event.target.files[0]);
    http(`products/file/${id}`).asFile(event.target.files[0]).then(() => loadFile(id));
  }
 
  const loadFile = (id) => {
      if(id > 0)
        http(`products/file/${id}/get`).asGetFile().then(resp => {
            setFile(resp);
        });
      else
        setFile(null);
  }

  useEffect(() => {    
    loadFile(id);
  }, [id])

  return (
    <div id="upload-box" style={{overflow:'hidden'}}>      
      {showInput && <input type="file" onChange={handleUpload}/>}
      {file && <ImageThumb image={file} showInput={showInput} />}
    </div>
  );
}

/**
 * Component to display thumbnail of image.
 */
const ImageThumb = ({ image, showInput }) => {
  const widt = showInput == true ? '160px' : '250px';
  const heig = showInput == true ? '160px' : '250px';
  return <img width={widt} height={heig} className="img-photo" src={URL.createObjectURL(image)} alt={image.name} />;
};

FileUpload.defaultProps = {
  onSave : () => {}
}

export default FileUpload
import moment from 'moment';

export const billDefault = {
    creditDays : 0,
    vendorId: null,
    areaId: 0,
    observaction: '',
    nameClient: '',
    clientId: null,
    rate : 0,
    date : new Date(moment().year(), moment().month(), moment().date(), 0, 0, 0, 0),
}

export const inPutProductDefault = {
    date : new Date(moment().year(), moment().month(), moment().date(), 0, 0, 0, 0),
}

export const outPutProductDefault = {
    ...inPutProductDefault
}

export const purchaseDefault = {
    areaId: 0,
    date : new Date(moment().year(), moment().month(), moment().date(), 0, 0, 0, 0),
    typeId:0,
    providerId: null,
    observaction: '',
    rate : 0,
    currencyId: 1,
}

export const movementDefault = {
    areaId: 0,
    date : new Date(moment().year(), moment().month(), moment().date(), 0, 0, 0, 0),
    movementTypeId:undefined,
    observaction: '',   
}

export const traslateDefault ={
    date : new Date(moment().year(), moment().month(), moment().date(), 0, 0, 0, 0),
    areaId: 0,
    observaction: '',
}


export const defaultFacturasDetalle = {
    cantidad: 0,
    precio: 0,
    descuentoAverage: 0,
    importe: 0,
    inventarioId: 0,
    ivaAverage: 0.15,
    ivaMonto: 0,
    subTotal: 0,
    total: 0
}

export const receiptDefault = {
    id: 0,
    billId: 0,
    amount : 0,   
    retention : 0,   
    balance : 0,   
    observation: '',    
    client: '',    
    paid :false,
    applyPayment : false,
    createAt: new Date(moment().year(), moment().month(), moment().date(), 0, 0, 0, 0),
} 




export const productDefault = {
    id:0,
    description: "",
    familyId: null,
    hasIva: false,
    stockInherited: false,
    name: "",
    presentationId: null,
    stateId: 1,
    stock: 0,
    unitOfMeasureId: null,
    convertProductId: 0,
    convertProductQuantity: 0,
    convertSourceQuantiy: 1
}

export const labels = {
    precio : 'Precio',
    costo : 'Costo',
}

const today = () => new Date(moment().year(), moment().month(), moment().date(), 0, 0, 0, 0);
const firstDay = () => moment().set('date', 1);

export const dateByReport = {
    from: firstDay(),
    to: today()
}

export const defaultDate = {
    from: firstDay(),
    to: today()
}


export const defaultLabels = {
    family: 'Categoria',
    presentacion: 'Marcas',
}

export const defaultProvider = {
    id: 0,
    name: '',
    property: '',
    ruc: '',
    phoneNumber: '',
    email: '',
    address: '',
    observation: '',
    active: true,

}

export const defaultClient = {
    id: 0,
    identification: '',
    name: '',
    lastName: '',
    address: '',
    isCompany: false,

}

export const convert2 = value => parseFloat(parseFloat(value).toFixed(2));
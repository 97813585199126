import React from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FileUpload from './FileUpload';
const ImageProduct = () => {

    //get id from route
    const { id } = useParams();

    const product = JSON.parse(localStorage.getItem('imageProduct'));

    const { internalCode, name, family, presentation, um, price } = product;

    return (
        <div className='container medium'>
            <h4>{internalCode} - {name}</h4>
            <FileUpload id={id} showInput={false} />
            <div><span className='text-muted'>Categoria</span>:<span className='text-bold'>{family}</span></div>
            <div><span className='text-muted'>Marca</span>:<span className='text-bold'>{presentation}</span></div>
            <div><span className='text-muted'>Unidad de medida</span>:<span className='text-bold'>{um}</span></div>
            <div><span className='text-muted'>Precio</span>:<span className='text-bold'>{price}</span></div>
        </div>
    );
}

export default ImageProduct;

export const _path = {
    ROOTMAIN: '/app'
}

const menu = [{
    layout: 'app',
    title: 'Cotizaciones',
    url: `${_path.ROOTMAIN}/cotizaciones`,
    submenu: {
        type: 'menu',
        menu: [
            { title: 'Cotizaciones', url: `${_path.ROOTMAIN}/cotizaciones` },
            { title: 'Preventas', url: `${_path.ROOTMAIN}/pre-ventas` },
        ],
    },
},{
    layout: 'app',
    title: 'Facturacion',
    url: `${_path.ROOTMAIN}/facturas`,
    submenu: {
        type: 'menu',
        menu: [
            { title: 'Facturas', url: `${_path.ROOTMAIN}/facturas` },
            { title: 'Actualizar Facturas', url: `${_path.ROOTMAIN}/facturas-updates` },            
            { title: 'Gestión de caja', url: `${_path.ROOTMAIN}/cierres-caja` },  
            { title: 'Recibos', url: `${_path.ROOTMAIN}/recibos` },  
            { title: 'Nota de Crédito', url: `${_path.ROOTMAIN}/notascreditos` },  
            { 
                title: 'Reportes', 
                url: ``,
                submenu : [
                    { title: 'Ventas', url: `${_path.ROOTMAIN}/facturas/reportes/ventas` },
                    { title: 'Recibos', url: `${_path.ROOTMAIN}/facturas/reportes/recibos` },
                    { title: 'Comisiones', url: `${_path.ROOTMAIN}/facturas/reportes/comisiones` },
                    { title: 'Dashboard', url: `${_path.ROOTMAIN}/facturas/reportes/dashboard`, icon: 'dashboard.png' },
                ]
            },   
        ],
    },
},{
    layout: 'app',
    title: 'Inventario',
    url: `${_path.ROOTMAIN}/productos` ,
    submenu: {
        type: 'menu',
        menu: [
            { title: 'Productos', url: `${_path.ROOTMAIN}/productos` },           
            { title: 'Grupos', url: `${_path.ROOTMAIN}/grupos` },
            { title: 'Marcas', url: `${_path.ROOTMAIN}/marcas` },
            { title: 'Unidad Medida', url: `${_path.ROOTMAIN}/unidadmedida` },          
            { title: 'Tasa de cambio', url: `${_path.ROOTMAIN}/tasa-de-cambio` },
            { title: 'Existencias por fecha', url: `${_path.ROOTMAIN}/inventario/reportes/existencias-fecha` },
            { 
                title: 'Reportes', 
                url: ``,
                submenu : [
                    { title: 'Kardex', url: `${_path.ROOTMAIN}/inventario/reportes/kardex` },
                    { title: 'Existencias', url: `${_path.ROOTMAIN}/inventario/reportes/existencias` },
                    { title: 'Stock', url: `${_path.ROOTMAIN}/inventario/reportes/stock` },
                    { title: 'Ult ingresos', url: `${_path.ROOTMAIN}/inventario/reportes/ingresos` },
                    { title: 'Existencias por fecha', url: `${_path.ROOTMAIN}/inventario/reportes/existencias-fecha` }
                ]
            }
        ],
    }
},{
    layout: 'app',
    title: 'Clientes',
    url: `${_path.ROOTMAIN}/clientes` ,
    submenu: {
        type: 'menu',
        menu: [
            { title: 'Gestión de clientes', url: `${_path.ROOTMAIN}/clientes` },           
            { title: 'Cuentas por cobrar', url: `${_path.ROOTMAIN}/clientes/pending` },           
        ],
    },
},{
    layout: 'app',
    title: 'Compras',
    url: `${_path.ROOTMAIN}/compras` ,
    submenu: {
        type: 'menu',
        menu: [
            { title: 'Lista de compras', url: `${_path.ROOTMAIN}/compras` },           
            { title: 'Proveedores', url: `${_path.ROOTMAIN}/proveedores` },          
            { 
                title: 'Reportes', 
                url: ``,
                submenu : [
                    { title: 'Compras de productos', url: `${_path.ROOTMAIN}/compras/reportes/` },     
                ]
            }     
        ],
    },
},{
    layout: 'app',
    title: 'Entradas',
    url: `${_path.ROOTMAIN}/movimientos/entradas` ,
    submenu: {
        type: 'menu',
        menu: [           
            { title: 'Ajuste', url: `${_path.ROOTMAIN}/movimientos/entradas` },
            { title: 'Inventario Inicial', url: `${_path.ROOTMAIN}/movimientos/inv-inicial` },
            { title: 'Solicitud de traslado', url: `${_path.ROOTMAIN}/movimientos/traslados` },    
        ],
    },
},{
    layout: 'app',
    title: 'Salidas',
    url: `${_path.ROOTMAIN}/movimientos/salidas` ,
    submenu: {
        type: 'menu',
        menu: [
            { title: 'Ajustes de salida', url: `${_path.ROOTMAIN}/movimientos/salidas` },
            { title: 'Despachos', url: `${_path.ROOTMAIN}/movimientos/despacho` },    
        ],
    },
},{
    layout: 'app',
    title: 'Configuración',
    url: `${_path.ROOTMAIN}/usuarios` ,
    submenu: {
        type: 'menu',
        menu: [
            { title: 'Usuarios', url: `${_path.ROOTMAIN}/usuarios` },
            { title: 'Roles', url: `${_path.ROOTMAIN}/roles` },
            { title: 'Recursos', url: `${_path.ROOTMAIN}/permisos` },
            { title: 'Bodegas', url: `${_path.ROOTMAIN}/areas` },   
            { title: 'Bancos', url: `${_path.ROOTMAIN}/bancos` },   
            { title: 'Justificación de Notas Crédito', url: `${_path.ROOTMAIN}/gruponotacredito` },   
            { title: 'Autorizaciones', url: `${_path.ROOTMAIN}/authorizations` },   
            { title: 'Comisión por Categoria', url: `${_path.ROOTMAIN}/comission-config` },   
        ],
    },
}];


export default menu;
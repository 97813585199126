import React from 'react';
import DataGrid, {
    Column,
    Scrolling,
    Selection,
    FilterRow,
    Button,
    Editing
} from 'devextreme-react/data-grid';
import DropDownBox from 'devextreme-react/drop-down-box';
import notify from 'devextreme/ui/notify';
import { cellRender } from '../../utils/common';
import CustomStore from 'devextreme/data/custom_store';
import http from '../../utils/http';
import { defaultLabels } from '../../data/defaultObjects';

const dropDownOptions = { fullScreen: true, showCloseButton: true };

export default class ProductDDBComponent extends React.Component {
    quantity = 'quantity';

    constructor(props) {
        super(props);
        
        this.state = {
            currentValue: props.data.value
        };
        this.dropDownBoxRef = React.createRef();
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.contentRender = this.contentRender.bind(this);
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.calculateFilterExpression = this.calculateFilterExpression.bind(this);
        this.showImage = this.showImage.bind(this);
        this.showPrice = props.showPrice;
        this.validateQuantity = props.validateQuantity;

        this.quantity = props.quantity || this.quantity;
    }

    customStore(url){
        return new CustomStore({
            key:"id",
            load: (loadOptions) => {
    
                let params = {};
                params.skip = loadOptions.skip || 0;
                params.take = loadOptions.take || 10;    
              
                if (loadOptions.filter) {
                    if (typeof loadOptions.filter[0] == 'object') {
    
                        let moreParams = {};
    
                        const dataFilter = filters =>{
                            
                            for (var filter in filters) {
                                if (filters.hasOwnProperty(filter)) {
    
                                    if(['columnIndex','filterValue'].includes(filter)) continue;
    
                                    const element = filters[filter]
                                    
                                    if(['!=','==','<','>','<=','>=','and','or'].includes(element)) continue;
                                    
                                    if (typeof element == 'object') {
                                        dataFilter(element);
                                    }else{
                                        if(moreParams[filters[0]])
                                        moreParams[`${filters[0]}End` ] = filters[2];
                                        else
                                        moreParams[filters[0]] = filters[2];
                                        break;
                                    }
                                }
                            }
    
                        };
    
                        dataFilter(loadOptions.filter);                    
                        params = { ...params, ...moreParams};
    
                    } else {
                        params[loadOptions.filter[0]] = loadOptions.filter[2];
                    }
                }
    
                return http(url)
                    .asGet(params)
                    .then((data) => {

                        return {
                            data:data.items,
                            totalCount: data.totalCount,
                        };

                    })
                    .catch(() => { throw 'Data Loading Error'; });
            },
            byKey: id => http(`products/get/${id}`).asGet()
        })
    }

    onCellPrepared(e) {

        if (e.rowType == 'data') {
            if(e.column.dataField == "stock")
                if(e.value == 0)
                    e.cellElement.classList.add('stock-0');
           
        }
    }   

    calculateFilterExpression(value, selectedFilterOperation, target) {  

        const values = value.split('%');
        let filters = values.map(v => {
            return ['name', 'contains', v];
        });

        const filterFull = [];

        if (filters.length > 1) {

           for (let index = 0; index < filters.length; index++) {
            const element = filters[index];
            filterFull.push(element);
            if (index < filters.length - 1) {
                filterFull.push('and');
            }
           }
           return filterFull;

        }else{
            return [filters]
        }
    }

    showImage(e){
        e.preventDefault();
        localStorage.setItem('imageProduct', e.row.data);
        window.open(`app/products/get/image/${e.row.data.id}`, '_blank')
    }

    contentRender() {
        return (
            <DataGrid
                dataSource={this.props.data.column.lookup.dataSource}
                keyExpr="id"                
                height={'100vh'}            
                selectedRowKeys={[this.state.currentValue]}
                hoverStateEnabled={true}
                onSelectionChanged={this.onSelectionChanged}
                focusedRowEnabled={true}
                defaultFocusedRowKey={this.state.currentValue}
                rowAlternationEnabled={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                onCellPrepared ={this.onCellPrepared}
                onToolbarPreparing={this.onToolbarPreparing}
                
            >
                <FilterRow visible={true} />
                <Column dataField="internalCode" caption="Codigo" width={110} ></Column>
                <Column dataField="name" caption="Nombre" calculateFilterExpression={this.calculateFilterExpression}></Column>
                <Column dataField="family" caption={defaultLabels.family} width={120} visible={true}></Column>
                <Column dataField="um" caption="Unidad Medida" width={120}></Column>
                <Column dataField="stock" caption="Cant" width={90}></Column>
                <Column dataField="disponible" caption="Disp" width={90}></Column>
                <Column dataField="cost" caption="Costo" visible={!this.showPrice} cellRender={cellRender()} width={90}></Column>                 
                <Column dataField="price" caption="Precio" visible={this.showPrice} cellRender={cellRender()} width={90}></Column>
                <Column type="buttons" width={90}>
                    <Button name="edit" icon="image" onClick={e => (localStorage.setItem('imageProduct', JSON.stringify(e.row.data)),window.open(`app/products/get/image/${e.row.data.id}`, '_blank'))} />
                </Column>
                <Editing
                    mode="popup"
                    allowUpdating={true}
                    useIcons={true}
                ></Editing>
                <Scrolling mode="virtual" />
                <Selection mode="single" />
            </DataGrid>
        );
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'before',
            widget: 'dxButton',
            options: {
                text: 'Regresar',
                type: 'normal',
                icon: 'chevronleft',
                stylingMode: "outlined",
                onClick: () => this.dropDownBoxRef.current.instance.close()
                
            }
        });
    }

    onSelectionChanged(e) {

        this.setState({ currentValue: e.selectedRowKeys[0] });
        this.props.data.setValue(this.state.currentValue);
        
        if (e.selectedRowKeys.length > 0) {                   
           
            const data = e.selectedRowsData[0];
            if(this.validateQuantity && data.stock <= 0){
                notify("No hay stock disponible", "error", 1000);
            }else{
                this.dropDownBoxRef.current.instance.close();     
                this.props.data.component.editCell(this.props.data.rowIndex, this.quantity);
            }
        }

    }

    

    render() {
        return (
            <DropDownBox
                ref={this.dropDownBoxRef}
                key="id"
                dropDownOptions={dropDownOptions}
                dataSource={this.props.data.column.lookup.dataSource}
                value={this.state.currentValue}
                displayExpr={item => item ? `${item.internalCode} - ${item.name}` : ''}
                valueExpr="id"                
                contentRender={this.contentRender}>
            </DropDownBox>
        );
    }
}
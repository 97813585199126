import React, { useEffect, useRef, useState } from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import Form, { SimpleItem, GroupItem, Label, EmptyItem, StringLengthRule, RequiredRule, EmailRule, AsyncRule } from 'devextreme-react/form';
import { useDispatch, useSelector } from 'react-redux'
import Box, { Item } from 'devextreme-react/box';
import { dialogProduct } from '../../store/product/productDialogReducer';
import { createCustomStore, createStoreLocal } from '../../utils/proxy';
import { editorOptionsNumberBox, editorOptionsSelect, editorOptionsSwitch, editorOptsTextBox } from '../../data/app';
import { Button } from 'devextreme-react/button';
import http from '../../utils/http';
import uri from '../../utils/uri';
import notify from 'devextreme/ui/notify';
import { defaultLabels, productDefault } from '../../data/defaultObjects';
import { formatId } from '../../utils/common';
import FileUpload from './FileUpload';
//import { useBarcode } from 'react-barcodes';

const Nuevo = props => {

    let refProduct = useRef();

    const [product, setProduct] = useState({ ...productDefault });
    const [saving, setSaving] = useState(false);
    const [reloadScreen, setReloadScreen] = useState(0);
    const [reload, setReload] = useState(false);
    //const [stockInherited, setStockInherited] = useState(false);
    const dispatch = useDispatch();
    const { open, id } = useSelector(store => store.productDialog);

    const closeDialog = (load) => {

        refProduct.current.instance.resetValues();
        setProduct({ ...productDefault });

        dispatch(dialogProduct({ open: false, id: 0 }));

        if (load) {

            let { onSave } = props;
            onSave();

        }
    }

    const onHiding = ({ load }) => {
       
        closeDialog(load);

    }

    const guardarProduct = (e) => {
        
        let resultValidate = refProduct.current.instance.validate();

        resultValidate.complete.then(result => {
            if (result.isValid) {

                setSaving(true);

                http(uri.products.insert).asPost({ ...product })
                    .then(resp => {
                        setSaving(false);
                        notify(`Producto creado correctamente`);
                        closeDialog(true);
                    })
                    .catch(err => {
                        setSaving(false);
                        notify(err, 'error');
                    })
            }
        });
    }

    useEffect(() => {

        if (id > 0) {
            http(uri.products.getById(id)).asGet()
                .then(resp => {
                    setProduct({ ...resp });
                })
        }

    }, [id]);

    const validationCallback = (e) => {
        return new Promise(resolve => {
            resolve(
                (product.convertProductId > 0 && (e.value != '' && e.value != null)) 
            ||  ((product.convertProductId == '' ||  product.convertProductId == 0 ||  product.convertProductId == null) 
                    && (e.value == '' ||  e.value == 0 ||  e.value == null)));
        });
    }

    const validationCallback2 = (e) => {
        return new Promise(resolve => {
            resolve(
                (product.convertProductId > 0 && (e.value != '' && e.value != null && e.value > 0)) 
            ||  ((product.convertProductId == '' ||  product.convertProductId == 0 ||  product.convertProductId == null) 
                    &&  e.value == 0 ));
        });
    }

    const buttonOptions = {
        text: 'Actualizar',
        icon: 'refresh',
        onClick: function(e) { 
            setReload(true);
            setReloadScreen(reloadScreen + 1);
        }
    };

    console.log({id});

    const isNew = id == 0 || id == undefined || id == null;

    const active = true;

    return (
        <div>
            <Popup
                width={900}
                height={'90vh'}
                title={isNew ? `Nuevo producto` : `Producto #${formatId(id)}`}
                onHiding={onHiding}
                visible={open}
                className="bg-fbfbfb"
            >
                 <ToolbarItem
                    widget="dxButton"
                    location="after"
                    options={buttonOptions}>
                </ToolbarItem>
                <Box direction="row" width="100%">
                    <Item ratio={1}>
                        <FileUpload id={id} showInput={true}/> 
                    </Item>
                    <Item ratio={3}>
                    <Form formData={product} ref={refProduct}>
                    <GroupItem colCount={2}>
                       
                        <SimpleItem dataField="id" colSpan={1} editorType="dxNumberBox" editorOptions={{
                            disabled: true
                        }}>
                            <Label text="Codigo" />
                        </SimpleItem>
                        <SimpleItem dataField="internalCode" colSpan={1} editorType="dxTextBox" editorOptions={{
                            ...editorOptsTextBox,
                            disabled: false
                        }}> 
                            <Label text="Codigo interno" />
                        </SimpleItem>
                        <SimpleItem dataField="name" colSpan={2}>
                            <Label text="Nombre" />
                            <RequiredRule message="Ingrese el nombre" />
                            <StringLengthRule max={100} min={2} message="Máximo de caracteres 50 y 2 mínimo" />
                        </SimpleItem>
                        <SimpleItem dataField="description" colSpan={2}>
                            <Label text="Nota" />
                            <StringLengthRule max={150} message="Máximo de caracteres 150" />
                        </SimpleItem>
                        <SimpleItem dataField="familyId" editorType="dxSelectBox"
                            editorOptions={{
                                dataSource: createStoreLocal({ name: 'family', active, reload }),
                                ...editorOptionsSelect
                            }} >
                            <Label text={defaultLabels.family} />
                            <RequiredRule message={"Seleccione el " + defaultLabels.family} />
                        </SimpleItem>
                        <SimpleItem dataField="unitOfMeasureId" editorType="dxSelectBox"
                            editorOptions={{
                                dataSource: createStoreLocal({ name: 'unitOfMeasure', active, reload }),
                                ...editorOptionsSelect
                            }} >
                            <Label text="Unidad Med." />
                            <RequiredRule message="Seleccione la unida medida" />
                        </SimpleItem>
                        <SimpleItem dataField="presentationId" editorType="dxSelectBox"
                            editorOptions={{
                                dataSource: createStoreLocal({ name: 'presentation', active, reload }),
                                ...editorOptionsSelect
                            }} >
                            <Label text={defaultLabels.presentacion} />
                            <RequiredRule message="Seleccione el estado" />
                        </SimpleItem>  
                        <SimpleItem dataField="stateId" editorType="dxSelectBox"
                            editorOptions={{
                                dataSource: createStoreLocal({ name: 'productState', active, reload }),
                                ...editorOptionsSelect
                            }} >
                            <Label text="Estado" />
                            <RequiredRule message="Seleccione el estado" />
                        </SimpleItem>                        
                                              
                        {/* <SimpleItem dataField="stock" editorType="dxNumberBox">
                            <Label text="Minimo" />
                            <RequiredRule message="Ingrese el Minimo" />
                        </SimpleItem> */}
                        <SimpleItem dataField="hasIva" editorType="dxSwitch"
                            editorOptions={editorOptionsSwitch}>
                            <Label text="Aplica IVA" />
                        </SimpleItem>
                        <SimpleItem dataField="isService" editorType="dxSwitch"
                            editorOptions={editorOptionsSwitch}>
                            <Label text="Es Servicio?" />
                        </SimpleItem>
                    </GroupItem>
                    <GroupItem colCount={2} caption='Conversion'>                            
                        <SimpleItem colSpan={1} dataField="convertSourceQuantiy" editorType="dxNumberBox" editorOptions={{
                            ...editorOptionsNumberBox
                        }}>
                            <Label text="Cantidad" />
                            <AsyncRule message="Ingrese la cantidad" validationCallback={validationCallback2}></AsyncRule>
                        </SimpleItem> 
                        <EmptyItem colSpan={1} />
                        <SimpleItem colSpan={2} dataField="convertProductId" editorType="dxSelectBox"
                            editorOptions={{
                                dataSource: createCustomStore({ url: 'catalogos/products' })(),
                                ...editorOptionsSelect,
                                displayExpr: item => item ? `${item.internalCode} - ${item.name} (${item.um})` : '', 
                            }} >
                            <Label text="Convertir a" />
                        </SimpleItem>
                        <SimpleItem colSpan={1} dataField="convertProductQuantity" editorType="dxNumberBox" editorOptions={{
                            ...editorOptionsNumberBox
                            }}>
                            <Label text="Cantidad" />
                            <AsyncRule message="Ingrese la cantidad a convertir" validationCallback={validationCallback}></AsyncRule>
                        </SimpleItem> 
                    </GroupItem>
                </Form>
                    </Item>
                </Box>               
                <br />
                <div>
                    <Button
                        width={120}
                        text={`${saving ? 'Guardando...' : 'Guardar'}`}
                        type="default"
                        icon="save"
                        stylingMode="contained"
                        className="m-1"
                        disabled={saving}
                        onClick={guardarProduct}
                        />
                </div>
            </Popup>
        </div>
    );
}

export default Nuevo;

import React, { useRef } from 'react';
import { DataGrid } from 'devextreme-react';
import { Column, ColumnChooser, Editing, Lookup, Pager, Paging, Summary, TotalItem } from 'devextreme-react/data-grid';
import BlockHeader from '../../components/shared/BlockHeader';
import { onCellPreparedReceipt } from '../../components/bill/CellPrepared';
import { cellRender, dataFormatId, formatId, formatIdValue, formatToMoney } from '../../utils/common';
import { store } from '../../services/store';
import uri from '../../utils/uri';
import { formatDate, formatDateTime } from '../../data/app';
import urlReport from '../../services/reportServices';

const ReceiptByBillId = (props) => {

    const { onSave } = props;
    const refGrid = useRef();

    const onRowRemoved = (e) => {
        onSave();
    }

    const addMenuItems =(e) => {
        
        if (e.target == "content") {
            
            // e.items can be undefined
            if (!e.items) e.items = [];
 
            // Add a custom menu item
            if(e.rowIndex >= 0)
                e.items.push({

                    text: `Imprimir recibo`,
                    icon : 'print',
                    onItemClick: () => {
                        const report = urlReport();
                        report.print(`${report.receiptTicket(e.row.data.id)}`);
                    }
                    
                });
           
        }
    }

    const { id, reference, cliente } = props;
    const titleCliente = `Cliente: ${cliente}`;
    
    return (<>
            <BlockHeader title={titleCliente} />
            <DataGrid
                id="gridReceipt"
                ref={refGrid}
                selection={{ mode: 'single' }}
                dataSource={store({ uri: { get : uri.receivables.byBill(id), remove : uri.receivables.remove } })}
                showBorders={true}
                showRowLines={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                rowAlternationEnabled={true}        
                onCellPrepared={onCellPreparedReceipt}     
                onRowRemoved={onRowRemoved}     
                //onContextMenuPreparing={addMenuItems} 
            >
                <Paging defaultPageSize={20} />
                <Pager
                    showInfo={true}
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                />
                <ColumnChooser enabled={true} />
                <Column dataField="id" caption="Id" width={100} cellRender={dataFormatId} />
                <Column dataField="referencePhysical" caption="Recibo #" width={90} cellRender={dataFormatId} />
                <Column dataField="amount" caption="Pagado"  width={110} cellRender={cellRender()} />
                <Column dataField="balance" caption="Pendiente"  width={100} cellRender={cellRender()} />
                <Column dataField="retention" caption="Retención"  width={100} cellRender={cellRender()} />
                <Column dataField="paymentMethod" caption="Forma Pago"  width={100} />
                <Column dataField="banckId" caption="Banco" visible={false} />
                <Column dataField="reference" caption="Referencia" visible={false} />

                <Column dataField="observation" caption="Observacion" />
                <Column dataField="createBy" caption='Creado por' width={100} />
                <Column dataField="createAt" caption="Creado el" dataType='date' format={formatDateTime} width={140} />
                <Summary>                
                    <TotalItem
                        column="amount"
                        summaryType="sum" 
                        customizeText={data => `${formatToMoney(data.value)}`} />
                </Summary>         
                <Editing
                    mode="popup"                 
                    allowDeleting={true}
                    useIcons={true}
                ></Editing>     
            </DataGrid>
    </>);
    
}

export default ReceiptByBillId;

import React, { useEffect,  useState } from 'react';
import { DataGrid, Box,  DateBox, SelectBox, Button } from 'devextreme-react';
import { Item } from "devextreme-react/box";
import {
    Paging,
    Pager,   
    Column,    
    Export,
    GroupPanel,
    SearchPanel,
    Grouping,
    Summary,
    TotalItem,
    ColumnChooser,
    FilterRow,
} from 'devextreme-react/data-grid';
import Title from '../../components/shared/Title';
import BlockHeader from '../../components/shared/BlockHeader';

import { _path } from "../../data/headerNavigation";
import { formatDate, formatDateTime } from '../../data/app';
import { cellDataAsBold, cellRender, dataFormatId, formatToMoneyNumber, onCellPreparedSales, } from '../../utils/common';

import uri from '../../utils/uri';
import http from '../../utils/http';
import { dateByReport } from '../../data/defaultObjects';
import { userService } from '../../services/user.service';

const Recibos = (props) => {

    const user = userService.getUser();

    const [ areas, setAreas ] = useState([]);
    const [ areaId, setAreaId ] = useState(user.areaId);
    const [ productId, setProductId ] = useState(null);
    const [ users, setUsers ] = useState([]);
    const [ username, setUsername ] = useState([]);
    const [ dateFrom, setDateFrom ] = useState(dateByReport.from);
    const [ dateTo, setDateTo ] = useState(dateByReport.to);
    const [ data, setData ] = useState(undefined);
    const [ loading, setLoading ] = useState(false);    

    const changeUser = e => setUsername(e.value);    

    const changeDateFrom = e => setDateFrom(e.value);

    const changeDateTo = e => setDateTo(e.value);    

    useEffect(() => {
        http(uri.areas.get).asGet().then(resp => setAreas(resp));
        http(uri.users.get).asGet().then(resp => setUsers(resp));
    }, [0]);

    const loadReport = () => {
        if(areaId > 0 && dateFrom && dateTo){
            setLoading(true);
            http('reports/receipts').asPost({areaId, productId, from : dateFrom, to: dateTo}).then(resp => {
                setData(resp);
                setLoading(false);
            });      
        }
    };

    const toMoney = data => formatToMoneyNumber(data.value);  

    const title = 'Reporte de Recibos';

    return (
        <div className="container">
            <Title title={title} />
            <BlockHeader title={title} showFavorite={true} {...props}>
                
            </BlockHeader>
            <Box direction="row" width="100%" height={75}>                    
                <Item ratio={1}>
                    <div className="ml-10">
                        <label>Desde:</label>
                        <DateBox type="date" 
                            value={dateFrom}
                            displayFormat={formatDate} 
                            openOnFieldClick={true} 
                            onValueChanged={changeDateFrom}/>
                    </div>
                </Item>
                <Item ratio={1}>
                    <div className="ml-10">
                        <label>Hasta:</label>
                        <DateBox type="date" 
                            value={dateTo}
                            displayFormat={formatDate} 
                            openOnFieldClick={true} 
                            onValueChanged={changeDateTo}/>
                    </div>
                </Item> 
                <Item ratio={1}>
                    <div className="ml-10">
                        <label>Usuario:</label>
                        <SelectBox items={users}
                            placeholder="Selecciona"
                            showClearButton={true} valueExpr="username" displayExpr="username" 
                            value={username}
                            onValueChanged={changeUser}                         
                        />
                    </div>
                </Item>        
                     
                <Item ratio={1}>
                    <div style={{ paddingTop : '19px' }}>
                        <Button
                            width={120}
                            text={`${loading ? 'Buscando...' : 'Buscar'}`}
                            type="default"
                            icon="search"
                            stylingMode="contained"
                            className="m-1"
                            disabled={loading}
                            onClick={loadReport}
                            />
                        </div>
                </Item>
            </Box>
            <Box direction="row" width="100%" height={75}>

                <Item ratio={1}>                    
                    <DataGrid
                        id="gridContainer"
                        keyExpr="receipId"
                        selection={{ mode: 'single' }}
                        dataSource={data}
                        showBorders={true}
                        allowColumnResizing={true}
                        showRowLines={true}
                        hoverStateEnabled={true}
                        onCellPrepared={onCellPreparedSales}
                    >             
                        <GroupPanel visible={true} />
                        <SearchPanel visible={true} width={200}/>
                        <Grouping autoExpandAll={true} />          
                        <Paging defaultPageSize={10} />
                        <Pager
                            showInfo={true}
                            showPageSizeSelector={true}
                            allowedPageSizes={[10, 20, 50,100,500]}
                        />
                        <Export enabled={true} fileName={title} allowExportSelectedData={true} />  
                        <ColumnChooser enabled={true} />
                        <FilterRow visible={true} />
                        <Column dataField="receipId" caption="Id" width={100} cellRender={dataFormatId}/>                                     
                        <Column dataField="billId" caption="# Factura" width={80} cellRender={dataFormatId}/>                                     
                        <Column dataField="reference" caption="# Recibo"  width={80} cellRender={cellDataAsBold}/>    
                        <Column dataField="fullName" caption="Cliente" minWidth={200}/>                                     
                        <Column dataField="amount" caption="Abonado" cellRender={cellRender()}  width={100}/>                                     
                        <Column dataField="pending" caption="Pendiente" cellRender={cellRender()}  width={100}/>                                     
                        <Column dataField="retention" caption="Retención" cellRender={cellRender()}  width={100}/>                                     
                        <Column dataField="paymentMethod" caption="Forma Pago" width={110}/>    
                        <Column dataField="createAt"  dataType="date" caption="Fecha" format={formatDateTime} width={150}/>                                     
                        <Column dataField="observation" caption="Observación" />    
                        <Column dataField="createBy" caption="vendedor"  />    
                        <Summary>

                            <TotalItem column="billId" summaryType="sum" customizeText={() => 'Totales :'}/>                            
                            <TotalItem column="amount" summaryType="sum" customizeText={toMoney}/>
                            <TotalItem column="pending" summaryType="sum" customizeText={toMoney} />                           

                        </Summary>                
                    </DataGrid>
                </Item>               
            </Box>
        </div>
    );
}



export default Recibos;

import React, { useRef, useState } from 'react';
import { DataGrid } from 'devextreme-react';
import {
    Paging,
    Pager,
    FilterRow,
    HeaderFilter,
    ColumnChooser,
    Column,
    Lookup,
    Editing,
    Button as ButtonGrid,
    Export
} from 'devextreme-react/data-grid';
import { createStoreLocal } from '../../utils/proxy';
import Title from '../../components/shared/Title';
import { store } from '../../services/store';
import { _path } from "../../data/headerNavigation";
import { formatDate, formatDateTime } from '../../data/app';
import { cellAsPayoff, cellRender, dataFormatId, } from '../../utils/common';
import urlReport from '../../services/reportServices';


import uri from '../../utils/uri';
import BlockHeaderState from '../../components/shared/BlockHeaderState';

const BillUpdates = props => {

    const { clientId = 0 } = props;    
  
    let refGrid = useRef();

    const printReport = ( id ) => {
       
        const report = urlReport();
        report.print(`${report.billTicket(id)}`);

    }


    const onRowPrepared = (e) => {
        if (e.rowType == 'data') {
            if (!e.data.active)
                e.rowElement.classList.add('no-activo');

        }
    }

    const onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'before',
            widget: 'dxButton',
            options: {
                text: 'Exportar a excel',
                icon: 'exportxlsx',
                type: 'success',
                stylingMode: "outlined",
                onClick: () => refGrid.current.instance.exportToExcel(false)
            }
        });
    }

    const onCellPrepared = e => {

        const cellsQuantity = ['payoff']

        if (e.rowType == 'data') {
            if (cellsQuantity.includes(e.column.dataField))
                if (e.row.data.payoff)
                    e.cellElement.classList.add('pagada');
                else
                    e.cellElement.classList.add('pendiente');

            if (e.column.dataField == 'id')
                e.cellElement.classList.add('col-id-factura');
            if (e.column.dataField == 'reference')
                e.cellElement.classList.add('col-reference-factura');
            if (e.column.dataField == 'asignedTo' || e.column.dataField == 'creditDays' || e.column.dataField == 'deliveryDate' )
                e.cellElement.classList.add('bill-update');
        }

    }

    const extraParameter = clientId > 0 ? { key: 'clientId', value: clientId } : null;

    const cellRenderDelivery = (cellData) => {

        const { isDeliveried } = cellData.data;

        let deliveriedClassName = 'not-deliveried';
        if(isDeliveried)
            deliveriedClassName = 'is-deliveried';
        
        return (
            <div>
                <div className="current-value">
                    <span className={deliveriedClassName}> {isDeliveried ? 'Entregado': 'Sin entrega'} </span>
                </div>                
            </div>
        );
    };

    const title = 'Facturas';

    return (
        <div className="container">
            <Title title={title} />           
            <BlockHeaderState showFavorite={true} title={title} icon="icon-file-text"            
                onRefresh={() => refGrid.current.instance.refresh()}               
                visibleRefresh={true} {...props}
                >             
            </BlockHeaderState>         
            <DataGrid id="gridContainer"
                ref={refGrid}
                selection={{ mode: 'single' }}
                dataSource={store({ uri: {
                    get: uri.bills.get,                 
                    insert: uri.bills.base+'/update',
                    update: uri.bills.base+'/update'
                }, remoteOperations: true, extraParameter })}
                showBorders={true}
                showRowLines={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                //rowAlternationEnabled={true}
                hoverStateEnabled={true}              
                onRowPrepared={onRowPrepared}
                onToolbarPreparing={onToolbarPreparing}
                onCellPrepared={onCellPrepared}      
                remoteOperations={{
                    paging: true,
                    filtering: true
                }}     
            >
              
                <Paging defaultPageSize={20} />
                <Pager
                    showInfo={true}
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                />
                
                <Export enabled={false} fileName={title} />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <ColumnChooser enabled={true} />
                <Column dataField="id" caption="Id" width={70} cellRender={dataFormatId} allowEditing={false} />
                <Column dataField="date" caption="Fecha" dataType='date' format={formatDate} width={100}  allowEditing={false}/>
                <Column dataField="nombre" caption="Cliente" visible={clientId == 0} minWidth={150} allowHeaderFiltering={false}  allowEditing={false} />
                <Column dataField="paymentTypeId" width={100} caption="Tipo pago" allowEditing={false}>
                    <Lookup disabled={true} dataSource={createStoreLocal({ name: 'billPaymentType' })} valueExpr="id" displayExpr="name" />
                </Column>
                <Column dataField="total" width={100} cellRender={cellRender()}  allowEditing={false}/>
                <Column dataField="balance" width={100} caption="Deuda" cellRender={cellRender()}  allowEditing={false}/>
                <Column dataField="active" width={90} caption="Activa" visible={false} allowEditing={false}/>
                <Column dataField="payoff" width={100} caption='Pagada' dataType="boolean" cellRender={cellAsPayoff}  allowEditing={false}/>
                <Column dataField="asignedTo" width={100} caption="Vendedor">
                    <Lookup disabled={true} dataSource={createStoreLocal({ url: uri.users.base + '/vendors' })} valueExpr="username" displayExpr="username" />
                </Column>
                <Column dataField="creditDays" caption='Dias Crédito' width={100} />
                <Column dataField="deliveryDate" caption='Fecha Entregado' dataType='date' format={formatDate} width={140} />
                <Column dataField="createBy" caption='Creado Por' width={100}  visible={false}  allowEditing={false}/>
                <Column dataField="createAt" caption='Creado el' dataType='date' format={formatDateTime} visible={false} width={140}  allowEditing={false}/>
                <Column dataField="modifiedBy" caption='Modificado ' width={100} visible={false}  allowEditing={false}/>
                <Column dataField="modifiedAt" caption='Creado el' dataType='date' format={formatDateTime} visible={false} width={140}  allowEditing={false}/>
                <Column dataField="ConvertBy" caption='Modificada por' visible={false} width={100}  allowEditing={false}/>
                <Column dataField="isDeliveriede" width={100} caption="Entregado" cellRender={cellRenderDelivery}  allowEditing={false}/>              
                <Editing
                    mode="batch"
                    allowUpdating={true}
                    useIcons={true}
                ></Editing>
            </DataGrid>
        </div>
    );
}

export default BillUpdates;

import React,{ useRef } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    Editing,
    Export,
    FilterRow,
    HeaderFilter,
    Lookup,
    Pager,
    Paging,      
    Button as ButtonGrid 
} from 'devextreme-react/data-grid';
import BlockHeader from '../../../components/shared/BlockHeader';
import Title from '../../../components/shared/Title';
import uri from '../../../utils/uri';
import { useDispatch, useSelector } from 'react-redux';
import { store } from '../../../services/store';
import { createStore } from '../../../utils/proxy';
import Nuevo from './Nuevo';
import { dialogAreaProduct } from '../../../store/areaProduct/areaProductDialogReducer';
import onExporting from '../../../components/grids/Importer';
import { dataFormatId } from '../../../utils/common';
import { defaultLabels } from '../../../data/defaultObjects';
import useAuthorization from '../../../hooks/useAuthorization';
import { resources } from '../../../data/app';


const Stock = () => {

    const [ authorized, _, canSeeColCost ] = useAuthorization( resources.verReporteStock, resources.verColumnaCostoStock );

    const { areaId } = useSelector(store => store.user);

    const dataGrid = useRef();
    const dispatch = useDispatch();

    const openDialog = (e) => {

        const { productId, product } = e.row.data;
        dispatch(dialogAreaProduct({open : true, productId, name : product.name, internalCode: product.internalCode}));
    } 
        

    const onCellPrepared = e => {
        
        if (e.rowType == 'data') {    
            if(e.column.dataField == "stock" && e.data.stockMin)
                if(e.data.stock < e.data.stockMin)
                    e.cellElement.classList.add('txt-stockMin');  
                else
                    e.cellElement.classList.add('txt-stock');  

        }

    }

    const cb = data => data.map(x => {
        x.isLimit = x.stockMin ? x.stock <= x.stockMin : false
        return x;
    });

    const onSave = () => dataGrid.current.instance.refresh();

    const onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'before',
            widget: 'dxButton',
            options: {
                text: 'Exportar a excel',
                icon: 'exportxlsx',
                type: 'success',
                stylingMode: "outlined",
                onClick: () => dataGrid.current.instance.exportToExcel(false)
            }
        });
    }

    const calculateFilterExpression = (value, selectedFilterOperation, target) => {  

        const values = value.split('%');
        let filters = values.map(v => {
            return ['product.name', 'contains', v];
        });

        const filterFull = [];

        if (filters.length > 1) {

           for (let index = 0; index < filters.length; index++) {
            const element = filters[index];
            filterFull.push(element);
            if (index < filters.length - 1) {
                filterFull.push('and');
            }
           }
           return filterFull;

        }else{
            return [filters]
        }
    }
    

    const title = 'Stock';

    return authorized(
        <div className="container">
            <Title title={title} />
            <BlockHeader title={title} >
            </BlockHeader>     
            <Nuevo onSave={onSave} areaId={areaId}/>
            <DataGrid id="gridContainer"
                ref={dataGrid}
                selection={{ mode: 'single' }}
                dataSource={store({ uri: uri.areaProducts(areaId), cb  })}
                showBorders={true}
                showRowLines={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                hoverStateEnabled={true}
                onCellPrepared={onCellPrepared}
                onToolbarPreparing={onToolbarPreparing}
                onExporting={(e) => onExporting(e, title)}
            >
                <Paging defaultPageSize={20} />
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                    showInfo={true}
                />
                <Export enabled={false} fileName={title} allowExportSelectedData={true} />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <ColumnChooser enabled={true} />
                <Column dataField="areaId" visible={false} />
                <Column dataField="productId" caption='Id' width={70} visible={false}/>
                <Column dataField="product.internalCode" caption='Codigo'  width={90} cellRender={dataFormatId}/>
                <Column dataField="product.name" caption='Nombre' minWidth={120} calculateFilterExpression={calculateFilterExpression}/>
                <Column dataField="product.familyId" caption={defaultLabels.family} width={100}>
                    <Lookup disabled={true} dataSource={createStore({ name: 'family' })} valueExpr="id" displayExpr="name" />
                </Column>
                <Column dataField="product.presentationId" caption={defaultLabels.presentacion} width={100}>
                    <Lookup disabled={true} dataSource={createStore({ name: 'presentation' })} valueExpr="id" displayExpr="name" />
                </Column>
                <Column dataField="product.unitOfMeasureId" caption="UM" width={100}>
                    <Lookup disabled={true} dataSource={createStore({ name: 'UnitOfMeasure' })} valueExpr="id" displayExpr="name" />
                </Column>
                <Column dataField="stock" caption='Cantidad' width={100}/>
                { canSeeColCost && <Column dataField="costAvg" caption='Costo' width={90}/> }
                <Column dataField="price" caption='Precio' width={90}/>
                <Column dataField="inherit" caption='Heredado' width={100} allowHeaderFiltering={false} visible={false}/>
                <Column dataField="stockMin" caption="Mín" width={60} allowHeaderFiltering={false} visible={false}/>
                <Column dataField="stockMax" caption="Máx" width={60} allowHeaderFiltering={false} visible={false}/>
                <Column dataField="isLimit" caption="Agotado" width={80} allowHeaderFiltering={false}/>
                <Column dataField="discount" caption="% Desc" format="#0%" width={80} allowHeaderFiltering={false}/>
                { canSeeColCost && 
                    <Column type="buttons" width={60}>
                        <ButtonGrid name="edit" onClick={e => openDialog(e)}/>
                    </Column>
                }
                <Editing
                    mode="popup"
                    allo
                    useIcons={true}
                    allowUpdating={true}
                >                    
                </Editing>
            </DataGrid>
        </div>
    );
}

export default Stock;
